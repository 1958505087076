import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {InputErrorPipe} from '@shared/components/form/input-error.pipe';

@Component({
  selector: 'app-error-message',
  imports: [
    InputErrorPipe
  ],
  templateUrl: './error-message.component.html',
  styleUrl: './error-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent {
  errors = input<ValidationErrors | null>();
  format = input<string>();
  errorMessage = input<string>();
}
